import React from 'react';
import classNames from 'classnames';
import Header from '../../../layout/Header/Header';
// eslint-disable-next-line import/namespace
import { getListRoles } from '../../../helpers/helpers';
import CommonHeaderRight from './CommonHeaderRight';

const PageLayoutHeader = () => {
	const { roles } = getListRoles();

	return (
		<Header>
			<div className={classNames('header-left', 'col-md')}>
				<div className='d-flex col-row'>
					{process.env.REACT_APP_ENV && process.env.REACT_APP_ENV !== 'production' && (
						<div className='blink'>{process.env.REACT_APP_ENV.toUpperCase()}</div>
					)}
				</div>
			</div>
			<CommonHeaderRight roles={roles} />
		</Header>
	);
};

export default PageLayoutHeader;
