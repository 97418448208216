import React, { lazy } from 'react';

// define manual import for preventing hook restriction
const DUMMY = {
	CRUD: lazy(() => import('../views/CRUD')),
};

const Registrasi = {
	// rizky views
	Registrasi: lazy(() => import('../views/rizky/registrasi/index')),
	// eko views
	BankFormula: lazy(() => import('../views/eko/master_data/bank_formula/index')),
	BankFormulas: lazy(() => import('../views/rio/BankFormulas')),
};

const SampleIncoming = {
	SampleIncoming: lazy(() => import('../views/rizky/sample_incoming/index')),
};

const Trial = {
	Schedule: lazy(() => import('../views/Schedule')),
	Trial: lazy(() => import('../views/Trial')),
	Verification: lazy(() => import('../views/Verification')),
	FinalReport: lazy(() => import('../views/FinalReport')),
};

const Formulasi = {
	Formulasi: lazy(() => import('../views/Formulasi')),
};

const TrialPlant = {
	TrialBigPlant: lazy(() => import('../views/TrialBigPlant')),
	VerificationPlant: lazy(() => import('../views/VerificationPlant')),
	FinalReportPlant: lazy(() => import('../views/FinalReportPlant')),
};

const Report = {
	JobNumberSummary: lazy(() => import('../views/azhar/JobNumberSummary')),
	JobNumberCompare: lazy(() => import('../views/rio/JobNumberCompare')),
};

const PPI = {
	AdminRequest: lazy(() => import('../views/sodiq/AdminRequest')),
	ApprovalDepartment: lazy(() => import('../views/handri/ApprovalDepartment')),
	RequestPPI: lazy(() => import('../views/afif/ppi/RequestPPI')),
	SampleEvaluation: lazy(() => import('../views/bagus/master_data/SampleEvaluation')),
	ApprovalRND: lazy(() => import('../views/rio/ApprovalRND')),
};

const contents = [
	{
		path: null,
		element: <DUMMY.CRUD />,
		index: 'CRUD',
		exact: true,
	},
	{
		path: null,
		element: <Trial.Schedule />,
		index: 'Schedule',
		exact: true,
	},
	{
		path: null,
		element: <Trial.Trial />,
		index: 'Trial',
		exact: true,
	},
	{
		path: null,
		element: <Trial.Verification />,
		index: 'Verification',
		exact: true,
	},
	{
		path: null,
		element: <Trial.FinalReport />,
		index: 'FinalReportMiniplant',
		exact: true,
	},
	{
		path: null,
		element: <Formulasi.Formulasi />,
		index: 'Formulasi',
		exact: true,
	},
	{
		path: null,
		element: <Registrasi.Registrasi />,
		index: 'Registrasi',
		exact: true,
	},
	{
		path: null,
		element: <Registrasi.BankFormula />,
		index: 'BankFormula',
		exact: true,
	},
	{
		path: null,
		element: <Registrasi.BankFormulas />,
		index: 'BankFormulas',
		exact: true,
	},
	{
		path: null,
		element: <TrialPlant.TrialBigPlant />,
		index: 'TrialBigPlant',
		exact: true,
	},
	{
		path: null,
		element: <TrialPlant.VerificationPlant />,
		index: 'VerificationPlant',
		exact: true,
	},
	{
		path: null,
		element: <TrialPlant.FinalReportPlant />,
		index: 'FinalReportPlant',
		exact: true,
	},
	{
		path: null,
		element: <SampleIncoming.SampleIncoming />,
		index: 'SampleIncoming',
		exact: true,
	},
	{
		path: null,
		element: <Report.JobNumberSummary />,
		index: 'JobNumberSummary',
		exact: true,
	},
	{
		path: null,
		element: <Report.JobNumberCompare />,
		index: 'JobNumberCompare',
		exact: true,
	},
	{
		path: null,
		element: <PPI.AdminRequest />,
		index: 'AdminRequest',
	},
	{
		path: null,
		element: <PPI.ApprovalDepartment />,
		index: 'ApprovalDepartment',
	},
	{
		path: null,
		element: <PPI.ApprovalRND />,
		index: 'ApprovalRND',
		exact: true,
	},
	// afif
	{
		path: null,
		element: <PPI.RequestPPI />,
		index: 'RequestPPI',
		exact: true,
	},
	{
		path: null,
		element: <PPI.SampleEvaluation />,
		index: 'SampleEvaluation',
		exact: true,
	},
];
export default contents;
